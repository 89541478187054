import { render, staticRenderFns } from "./AmFeTable.vue?vue&type=template&id=3ff357b0"
import script from "./AmFeTable.vue?vue&type=script&lang=ts"
export * from "./AmFeTable.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports