













































import { Mixins, Component, Prop } from 'vue-property-decorator'
import ModalMixin from '@/amui/mixins/modalMixin'

@Component({
  name: 'AmFeDialog',
})
export default class AmFeDialog extends Mixins(ModalMixin) {
  static componentName = 'AmFeDialog'

  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_dialog_title',
        '对话框'
      )
    },
  })
  readonly title!: string
  @Prop({ default: 'default' }) paddingSize!: string
}
