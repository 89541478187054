



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'AmTooltip',
})
export default class AmTooltip extends Vue {
  static componentName = 'AmTooltip'

  @Prop({ default: '提示文本' }) readonly content!: string
  @Prop(String) readonly effect?: string
  @Prop(String) readonly placement?: string
  @Prop({ default: true }) readonly visibleArrow!: boolean
  @Prop({ default: true }) readonly enterable!: boolean
  @Prop({ default: false }) readonly openFilter!: boolean
  @Prop(String) readonly maxWidth?: string
  @Prop({ default: 'light' }) readonly themeMode!: string

  // 用于强制更新 tooltip 的key
  forceUpdateKey = Date.now()
  // tooltip maxWidth
  tooltipMaxWidth?: number

  @Watch('maxWidth', { immediate: true })
  handleMaxWidthChange() {
    if (this.maxWidth) {
      const maxWidth = Number(this.maxWidth.replace('px', ''))
      if (maxWidth && !isNaN(maxWidth)) this.tooltipMaxWidth = maxWidth
    } else {
      this.tooltipMaxWidth = void 0
    }
  }

  /**
   * 以下 3 个属性无法实时响应更新
   * 需要通过 key 强制 tooltip 更新
   * maxWidth 本身可以响应更新，但是将 maxWidth 从有值改为无值后不会响应更新
   */
  @Watch('maxWidth')
  @Watch('visibleArrow')
  @Watch('placement')
  handleForceUpdate() {
    this.forceUpdateKey = Date.now()
  }
}
