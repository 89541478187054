



































































import { Component, Prop, Vue } from 'vue-property-decorator'
import '../assets/css/AmSelect.scss'

@Component({
  name: 'AmSelect',
})
export default class AmSelect extends Vue {
  static componentName = 'AmSelect'
  //选项值集合
  @Prop({ type: Array, default: () => [] }) readonly options!: any[]
  //绑定值
  @Prop() readonly value!: any
  //是否多选
  @Prop({ default: false }) readonly multiple!: boolean
  //是否禁用
  @Prop({ default: false }) readonly disabled!: boolean
  //作为 value 唯一标识的键名，绑定值为对象类型时必填
  @Prop({ default: 'value' }) readonly valueKey!: string
  //作为 label 唯一标识的键名，绑定值为对象类型时必填
  @Prop({ default: 'label' }) readonly propLabel!: string
  //输入框尺寸
  @Prop() readonly size!: string
  //是否可以清空选项
  @Prop({ default: false }) readonly clearable!: boolean
  //多选时是否将选中值按文字的形式展示
  @Prop({ default: false }) readonly collapseTags!: boolean
  //多选时是否将选中值按文字的形式展示，并自适应填充
  @Prop({ default: true }) readonly collapseTagsAuto!: boolean
  //多选时用户最多可以选择的项目数，为 0 则不限制
  @Prop({ default: 0 }) readonly multipleLimit!: number
  //select input 的 name 属性
  @Prop() readonly name!: string
  //select input 的 autocomplete 属性
  @Prop({ default: 'off' }) readonly autocomplete!: string
  //占位符
  @Prop({ default: '请选择' }) readonly placeholder!: string
  //是否可搜索
  @Prop({ default: false }) readonly filterable!: boolean
  //是否允许用户创建新条目，需配合 filterable 使用
  @Prop({ default: false }) readonly allowCreate!: boolean
  //自定义搜索方法
  @Prop(Function) readonly filterMethod?: () => any
  //是否为远程搜索
  @Prop({ default: false }) readonly remote!: boolean
  //远程搜索方法
  @Prop(Function) readonly remoteMethod?: () => any
  //是否正在从远程获取数据
  @Prop({ default: false }) readonly loading!: boolean
  //远程加载时显示的文字
  @Prop({ default: '加载中' }) readonly loadingText!: string
  //搜索条件无匹配时显示的文字，也可以使用slot="empty"设置
  @Prop({ default: '无匹配数据' }) readonly noMatchText!: string
  //选项为空时显示的文字，也可以使用slot="empty"设置
  @Prop({ default: '无数据' }) readonly noDataText!: string
  //Select 下拉框的类名
  @Prop() readonly popperClass!: string
  //多选且可搜索时，是否在选中一个选项后保留当前的搜索关键词
  @Prop({ default: false }) readonly reserveKeyword!: boolean
  //在输入框按下回车，选择第一个匹配项。需配合 filterable 或 remote 使用
  @Prop({ default: false }) readonly defaultFirstOption!: boolean
  //是否将弹出框插入至 body 元素。在弹出框的定位出现问题时，可将该属性设置为 false
  @Prop({ default: true }) readonly popperAppendToBody!: boolean
  //对于不可搜索的 Select，是否在输入框获得焦点后自动弹出选项菜单
  @Prop({ default: false }) readonly automaticDropdown!: boolean
  //输入框右侧图标名
  @Prop() readonly iconName!: string
  //隐藏下拉选项
  @Prop({ default: false }) readonly hiddenOption!: boolean
  //使用 fixed-left 左侧栏插槽时，插槽宽度
  @Prop({ default: 30 }) readonly fixedLeftPadding!: number
  //使用 fixed-right 右侧栏插槽时，插槽宽度
  @Prop({ default: 30 }) readonly fixedRightPadding!: number
  //使用复选框展示选中状态
  @Prop() readonly multipleStyle!: string
  //是否隐藏边框
  @Prop({ default: false }) readonly noBorder!: boolean
}
