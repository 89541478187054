import { render, staticRenderFns } from "./AmBizUploadV2.vue?vue&type=template&id=4398d784&scoped=true"
import script from "./AmBizUploadV2.vue?vue&type=script&lang=ts"
export * from "./AmBizUploadV2.vue?vue&type=script&lang=ts"
import style0 from "./AmBizUploadV2.vue?vue&type=style&index=0&id=4398d784&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4398d784",
  null
  
)

export default component.exports