
















































import { Mixins, Component, Prop } from 'vue-property-decorator'
import ModalMixin from '@/amui/mixins/modalMixin'

@Component({
  name: 'AmDialog',
})
export default class extends Mixins(ModalMixin) {
  static componentName = 'AmDialog'

  @Prop({
    default: () => {
      return (window as any).lowcode.i18n.get(
        'apaas_amui_dialog_title',
        '对话框'
      )
    },
  })
  readonly title!: string
  @Prop() paddingSize!: string
}
