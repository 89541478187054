






































































































































































import { Component, Mixins } from 'vue-property-decorator'
import amTableColumnMixin from '../mixins/amTableColumnMixin'
import AmTableColumnTemplate from './AmTableColumnTemplate.vue'

@Component({
  components: { AmTableColumnTemplate },
  name: 'AmTableColumn',
})
export default class extends Mixins(amTableColumnMixin) {
  static componentName = 'AmTableColumn'
}
