import AmContainer from './layout/AmContainer.vue'
import AmRowContainer from './layout/AmRowContainer.vue'
import AmColContainer from './layout/AmColContainer.vue'
import AmCellContainer from './layout/AmCellContainer.vue'
import AmChildContainer from './layout/AmChildContainer.vue'
import AmGroup from './layout/AmGroup.vue'
import AmSplitLayout from './layout/AmSplitLayout.vue'
import AmSplitColumn from './layout/AmSplitColumn.vue'
import AmExpandSider from './layout/AmExpandSider.vue'
import AmLayout from './layout/AmLayout.vue'
import AmLayoutColumn from './layout/AmLayoutColumn.vue'
import AmInputField from './form/AmInputField.vue'
import AmDate from './form/AmDatePickerField.vue'
import AmText from './base/AmText.vue'
import AmButton from './base/AmButton.vue'
import AmLink from './base/AmLink.vue'
import AmLinkContainer from './base/AmLinkContainer.vue'
import AmImage from './base/AmImage.vue'
import AmPage from './layout/base/AmPage.vue'
import AmDatavPage from './layout/base/AmDatavPage.vue'
import AmTemplate from './layout/base/AmTemplate.vue'
import AmDialog from './base/AmDialog.vue'
import AmDrawer from './base/AmDrawer.vue'
import AmMark from './base/AmMark.vue'
import AmComp from './base/AmComp.vue'
// form
import AmFormElementContainer from './form/AmFormElementContainer.vue'
import AmCheckboxField from './form/AmCheckboxField.vue'
import AmRadioField from './form/AmRadioField.vue'
import AmSelectField from './form/AmSelectField.vue'
import AmSelect from './form/AmSelect.vue'
import AmCheckboxGroup from './form/AmCheckboxGroup.vue'
import AmMultiSelectField from './form/AmMultiSelectField.vue'
import AmUploadField from './form/AmUploadField.vue'
import AmUpload from './form/AmUpload.vue'
import AmRateField from './form/AmRateField.vue'
import AmBizSelectField from './form/AmBizSelectField.vue'
import AmInputSearchField from './form/AmInputSearchField.vue'
import AmNumberField from './form/AmNumberField.vue'
import AmTimePickerField from './form/AmTimePickerField.vue'
import AmTagField from './form/AmTagField.vue'
import AmDateRangePickerField from './form/AmDateRangePickerField.vue'
import AmRicheditorField from './form/AmRicheditorField.vue'
import AmRicheditor from './form/AmRicheditor.vue'
import AmBaseRichEditor from './form/AmBaseRichEditor.vue'
import AmDeptField from './form/AmDeptField.vue'
import AmPositionField from './form/AmPositionField.vue'
import AmUserField from './form/AmUserField.vue'
import AmUserGroupField from './form/AmUserGroupField.vue'
import AmTeacherField from './form/AmTeacherField.vue'
import AmIcon from './base/AmIcon.vue'
import AmSlotTable from './data/AmSlotTable.vue'
import AmSlotTableColumn from './data/AmSlotTableColumn.vue'
import AmSlotTableColumnEdit from './data/AmSlotTableColumnEdit.vue'
import AmTable from './data/AmTable.vue'
import AmTableColumn from './data/AmTableColumn.vue'
import AmCascaderField from './form/AmCascaderField.vue'
import AmFormItem from './form/AmFormItem.vue'

// compatible
import AmRadioFeild from './compatible/AmRadioFeild.vue'
import AmCheckboxFeild from './compatible/AmCheckboxFeild.vue'

// senior
import AmMenu from './senior/AmMenu'
import AmDropdownMenu from './senior/AmDropdownMenu/Index.vue'
import AmDropdownMenuForEdit from './senior/AmDropdownMenu/Edit.vue'
import AmDropdownMenuV2 from './senior/AmDropdownMenuV2/Index.vue'
import AmDropdownMenuForEditV2 from './senior/AmDropdownMenuV2/Edit.vue'
import AmTooltip from './senior/AmTooltip.vue'
import AmPopupContainer from './senior/AmPopupContainer/Index.vue'
import AmPopupContainerForEdit from './senior/AmPopupContainer/Edit.vue'
import AmPopupContainerItem from './senior/AmPopupContainer/Item.vue'
import AmBreadCrumb from './senior/AmBreadCrumb.vue'
import AmStep from './senior/AmStep.vue'
import AmInfiniteScroll from './senior/AmInfiniteScroll.vue'
import AmCustomRender from './senior/AmCustomRender.vue'
import AmAnchor from './senior/AmAnchor.vue'

import './assets/css/polyfill.scss'
import AmFrame from './senior/AmFrame.vue'
import AmSlotComponent from './layout/AmSlotComponent.vue'
import AmHtml from './senior/AmHtml.vue'
import AmDivider from './senior/AmDivider.vue'

//biz
import AmBizRangeSelector from './biz/AmBizRangeSelector.vue'
import AmBizUserSelector from './biz/AmBizUserSelector.vue'
import AmBizUpload from './biz/AmBizUpload.vue'
import AmBizUploadV2 from './biz/AmBizUploadV2.vue'
import AmBizTeacherSelector from './biz/AmBizTeacherSelector.vue'
import AmBizSelectKng from './biz/AmBizSelectKng.vue'
import AmBizCheckSinglePosition from './biz/AmBizCheckSinglePosition.vue'
import AmBizNavMain from './biz/AmBizNavMain.vue'
import AmBizVoice from './biz/AmBizVoice.vue'

// const version = process.env.VERSION || require('../../package.json').version
const version = require('./package.json').version
/**
 * 当前环境作用域
 */
const context: any = globalThis || {}
const isIe = document.all && context.external
export const lowcode = context.lowcode
if (typeof lowcode == 'undefined') {
  console.error(`找不到amcore,请先通过前端工程平台配置`)
}
export const logger = {
  context: context.console || {},
  trace: (type, args) => {
    const fun = logger.context[type] || function () {}
    // IE不支持打印
    if (isIe) return
    if (typeof fun == 'function') {
      try {
        return fun.apply(logger.context, args)
      } catch (err) {}
    }
    return null
  },
  log: function (...args) {
    logger.trace('log', args)
  },
  info: function (...args) {
    logger.trace('info', args)
  },
  warn: function (...args) {
    logger.trace('warn', args)
  },
  error: function (...args) {
    logger.trace('error', args)
  },
}
const components: any[] = [
  AmBizSelectField,
  AmDeptField,
  AmPositionField,
  AmUserField,
  AmUserGroupField,
  AmTeacherField,
  AmContainer,
  AmChildContainer,
  AmRowContainer,
  AmColContainer,
  AmCellContainer,
  AmGroup,
  AmSplitLayout,
  AmSplitColumn,
  AmExpandSider,
  AmLayout,
  AmLayoutColumn,
  AmInputField,
  AmPage,
  AmDatavPage,
  AmTemplate,
  AmDialog,
  AmDrawer,
  AmMark,
  AmDate,
  AmText,
  AmButton,
  AmLink,
  AmLinkContainer,
  AmImage,
  AmCheckboxFeild,
  AmRadioFeild,
  AmSelectField,
  AmSelect,
  AmCheckboxGroup,
  AmMultiSelectField,
  AmUploadField,
  AmUpload,
  AmIcon,
  AmSlotTable,
  AmSlotTableColumn,
  AmSlotTableColumnEdit,
  AmTable,
  AmTableColumn,
  AmRateField,
  AmInputSearchField,
  AmNumberField,
  AmTimePickerField,
  AmTagField,
  AmDateRangePickerField,
  AmRicheditorField,
  AmRicheditor,
  AmBaseRichEditor,
  AmMenu,
  AmDropdownMenu,
  AmDropdownMenuForEdit,
  AmDropdownMenuV2,
  AmDropdownMenuForEditV2,
  AmTooltip,
  AmPopupContainer,
  AmPopupContainerForEdit,
  AmPopupContainerItem,
  AmBreadCrumb,
  AmStep,
  AmInfiniteScroll,
  AmFormElementContainer,
  AmRadioField,
  AmCheckboxField,
  AmCascaderField,
  AmFrame,
  AmHtml,
  AmComp,
  AmSlotComponent,
  AmDivider,
  AmBizRangeSelector,
  AmBizUserSelector,
  AmBizUpload,
  AmBizUploadV2,
  AmBizTeacherSelector,
  AmBizSelectKng,
  AmBizCheckSinglePosition,
  AmBizNavMain,
  AmBizVoice,
  AmFormItem,
  AmCustomRender,
  AmAnchor,
]

try {
  const info = `%camui:${version}`
  logger.log(
    info,
    'color: #fff; border-radius: 3px; padding: 3px 7px;background: linear-gradient(315deg, #436BFF 0%, #436BFF 74%)'
  )
} catch (err) {}
export default components

// function install(vue) {
//   components.forEach(component => {
//     vue.component(component.componentName, component)
//   })
// }

// /* istanbul ignore if */
// if (typeof window !== 'undefined' && window.Vue) {
//   install(window.Vue)
// }

// export default {
//   version,
//   install,
// }
