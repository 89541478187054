














































































































import { Component, Mixins } from 'vue-property-decorator'
import amTableMixin from '../mixins/amTableMixin'
import AmTableColumn from './AmTableColumn.vue'

@Component({
  name: 'AmTable',
  components: { AmTableColumn },
})
export default class extends Mixins(amTableMixin) {
  static componentName = 'AmTable'
}
